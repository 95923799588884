
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import Insurer from "@/components/reusable/Insurer.vue";
import EditorWidget from "@/components/widgets/TinyMCE.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "create-underwriter-package-rate",
  components: { Field, ErrorMessage, Insurer, EditorWidget, GoBackButton },
  props: {
    publicId: String,
  },
  data() {
    return {
      // publicId: this.$route.params.publicId,
      redirect: "admin-underwriters-create-package",
      createRoute: variables.SUPERADMIN_UNDERWRITER_ROUTE,
      businessClass: "",
      loadings: [
        {
          name: "",
          type: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          rate_type: "",
          value: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          execution_order: 0,
          target: "",
        },
      ],
      featureId: "",
      unchecked: "",
      editors: [] as any,
    };
  },
  setup() {
    const {
      capitalize,
      getDynamicModelName,
      generateUUID,
      isEmptyArray,
    } = ResusableFunctions();
    const submitButton1 = ref<HTMLElement | null>(null);
    const underwriterPublicId = ref("");
    const defaultTerms = ref("");
    const displayFields = ref(false);
    const router = useRouter();
    const underwriters = ref([]);
    const actualTerms = ref([
      { content: { id: "", text: "" }, fqcn: "", id: "", publicId: "" },
    ]);

    const terms = ref([
      {
        businessType: "",
        name: "",
        publicId: "",
      },
    ]);
    const rates = ref([
      {
        name: "",
        publicId: "",
        value: "",
        unit: "",
      },
    ]);
    const defaultLoadings = ref([
      {
        id: "",
        name: "",
        publicId: "",
        rateType: "",
        type: "",
        value: "",
        unit: "",
      },
    ]);
    const businessClasses = ref([
      {
        name: "",
        packages: [],
        policyCount: 0,
        publicId: "",
        yearOfEstablishment: 0,
      },
    ]);

    const validationSchema = Yup.object().shape({
      underwriter: Yup.string().required(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      terms_and_conditions: Yup.array().of(
        Yup.object().shape({
          package: Yup.string().required(),
          terms: Yup.string().required(),
        })
      ),
      rates: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
      // eslint-disable-next-line @typescript-eslint/camelcase
      loadings_and_discounts: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
      // eslint-disable-next-line @typescript-eslint/camelcase
      additional_loadings_and_discounts: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(),
          type: Yup.string().required(),
          // eslint-disable-next-line @typescript-eslint/camelcase
          rate_type: Yup.string().required(),
          value: Yup.number().required(),
          // eslint-disable-next-line @typescript-eslint/camelcase
          execution_order: Yup.number().required(),
          target: Yup.string().required(),
        })
      ),
      // eslint-disable-next-line @typescript-eslint/camelcase
      applicable_packages: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
        })
      ),
    });

    const handleContentUpdate = (id, newContent) => {
      const editorItem = actualTerms.value.find((item) => item.publicId === id);
      if (editorItem) {
        // console.log(id, newContent);
        editorItem.content = newContent;
      }
    };

    // const submit = (values, { resetForm }) => {
    const submit = () => {
      const form = document.forms[0];
      const formData = new FormData(form);

      actualTerms.value.forEach((item, index) => {
        formData.append(
          getDynamicModelName(index, "terms_and_conditions", "terms"),
          item.content.text
        );
      });

      // const additionalLoadingsField = "additional_loadings_and_discounts";
      // const fields = [
      //   "name",
      //   "value",
      //   "type",
      //   "rate_type",
      //   "execution_order",
      //   "target",
      // ];

      // for (const field of fields) {
      //   const key = `${additionalLoadingsField}[0][${field}]`;

      //   if (formData.has(key)) {
      //     const value = formData.get(key);

      //     // Check if the value is empty (for strings) or 0 (for numbers)
      //     if (
      //       (typeof value === "string" && value.trim() === "") ||
      //       (typeof value === "number" && value === 0)
      //     ) {
      //       formData.delete(key);
      //     }
      //   }
      // }

      // return new Response(formData).text().then(console.log);

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(
              variables.SUPERADMIN_UNDERWRITERS_RATE_ROUTE,
              formData
            )
              .then((response) => {
                variables.toastAlert(
                  "Rates, terms & conditions was created successfully.",
                  "success"
                );
                router.push({
                  name: "admin-underwriters-users",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    // Function to get business classes
    const getBusinessClasses = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.BUSINESS_CLASSES)
          .then(({ data }) => {
            businessClasses.value = data.data.insurancePackages;
            defaultLoadings.value = data.data.loadings_and_discounts;
            underwriters.value = data.data.underwriters;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getBusinessClass = (event: any) => {
      businessClasses.value.forEach((item) => {
        if (item["publicId"] === event.target.value) {
          actualTerms.value = item["packages"];
          terms.value = item["packages"];
          rates.value = item["defaultRates"];
          displayFields.value = true;
          defaultTerms.value = item["defaultTerms"];
        }
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Underwriters", ["Create Package Rates"]);
      getBusinessClasses();
    });

    return {
      submitButton1,
      underwriters,
      underwriterPublicId,
      businessClasses,
      defaultLoadings,
      rates,
      terms,
      getBusinessClass,
      capitalize,
      submit,
      validationSchema,
      displayFields,
      getDynamicModelName,
      defaultTerms,
      actualTerms,
      generateUUID,
      handleContentUpdate,
      isEmptyArray,
    };
  },
  created() {
    //Set page title
    document.title =
      "Create Underwriter Package Rates | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addLoadingsField() {
      this.loadings.push({
        name: "",
        type: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        rate_type: "",
        value: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        execution_order: 0,
        target: "",
      });
    },
    removeLoadingsField(index) {
      // Remove loadings dynamic field
      $(".remove-loadings-" + index).remove();
    },
    // handleContentUpdated({ text, counter, modelName }) {
    //   // Handle the updated content here
    //   console.log("Updated Content: ", text);
    //   console.log("Index: ", counter);
    //   console.log("Model name: ", modelName);
    // },
    handleEditorContentUpdate({ index, content }) {
      this.editors = this.actualTerms as any;
      console.log(index.value, content);
      this.editors = this.editors.map((term, i) =>
        i === index.value ? { ...term, content } : term
      );
    },
  },
});
